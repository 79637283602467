import { FC, useState } from 'react';
import styles from './global-active-filters.module.css';
import { translate } from '../../../helpers/utils';
import { ColumnDef, FilterColumn, FilterType } from '../../../models';
import {
  deleteDimensionFiltersFromLocalStorage,
  getDimensionFiltersFromLocalStorage,
  saveDimensionFiltersToLocalStorage,
} from '../../../helpers/local-storage-helper';
import { getUpdatedFiltersToBeStored } from '../../../services/analytics-service';
import { useSelectFilterSet } from '../custom-filters/hooks/use-select-filter-set';
import { getDefaultFilterSetName } from '../custom-filters/helpers/default-filter-set-name';
import { useFilters } from '../../../global/context/filter-context';
import { getFilledFilters, getValidFilters } from '../custom-filters/helpers/validate-filters';
import { GlobalActiveFiltersProps, PopoverFilterType } from './global-active-filters.types';
import cn from 'classnames';
import { Tag } from '../../../core-ui/components/tag/tag';
import { HorizontalScroll } from '../../../core-ui/components/horizontal-scroll/horizontal-scroll';
import { useDefaultFiltersByView } from '../../../hooks/use-default-filters-by-view';
import { FilterPopover } from './filter-popover';
import trackerService from '../../../services/tracker/tracker-service';
import { useCurrentView } from '../../../hooks/use-current-view/use-current-view';

export const GlobalActiveFilters: FC<GlobalActiveFiltersProps> = ({
  filterStorageKey,
  showActiveFiltersLabel,
}) => {
  const { filters, columns, filterTypes, setFilters } = useFilters();
  const DEFAULT_FILTER_SET_NAME = getDefaultFilterSetName();
  const validFilters = getFilledFilters(getValidFilters(filters, columns)).reverse();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [popoverFilter, setPopoverFilter] = useState<PopoverFilterType | undefined>(undefined);
  const defaultFilters = useDefaultFiltersByView();
  const currentView = String(useCurrentView()).toLowerCase();
  const { setSelected: setIsSelectedFilterSet, storeSelected } = useSelectFilterSet(
    '',
    filterStorageKey,
  );

  const findTypeName = (condition: string) =>
    filterTypes.find((filterType: FilterType) => filterType.key === condition)?.name || '';
  const findColumn = (filter: FilterColumn) =>
    columns.find((columnTmp: ColumnDef) => columnTmp.key === filter.columnName)!;

  const removeFilter = (filter: FilterColumn) => {
    removeStoredFilter(filter);
    setFilters(
      filters.filter(
        (filterTmp: FilterColumn) =>
          filterTmp.columnLabel.toLowerCase() !== filter.columnLabel.toLowerCase(),
      ),
    );
    storeSelected(DEFAULT_FILTER_SET_NAME);
  };

  const removeStoredFilter = (filter: FilterColumn) => {
    const filtersStored = getDimensionFiltersFromLocalStorage(filterStorageKey);
    const filtersToAddOrEdit: FilterColumn[] = [];
    const filtersToRemoveFromStore = [filter];
    const updatedStoredFilters = getUpdatedFiltersToBeStored(
      filtersStored,
      filtersToAddOrEdit,
      filtersToRemoveFromStore,
    );
    saveDimensionFiltersToLocalStorage(updatedStoredFilters, filterStorageKey);
  };

  const clearFilters = () => {
    setFilters([]);
    setIsSelectedFilterSet('');
    deleteDimensionFiltersFromLocalStorage(filterStorageKey);
  };

  const handleApplyFilter = (filter: PopoverFilterType) => {
    const existingFilter = filters.find(filterTmp => filterTmp.columnLabel === filter.columnLabel);
    const newFilters = existingFilter
      ? filters.map(filterTmp =>
          filterTmp.columnLabel === filter.columnLabel ? filter : filterTmp,
        )
      : [...filters, filter];

    const filtersStored = getDimensionFiltersFromLocalStorage(filterStorageKey);
    const updatedStoredFilters = getUpdatedFiltersToBeStored(filtersStored, newFilters, []);

    saveDimensionFiltersToLocalStorage(updatedStoredFilters, filterStorageKey);
    setFilters(newFilters);
    trackerService.track(`Applied filter tag: ${filter.columnLabel} `, {
      [filter.columnLabel.replace(/\s+/g, '_').toLowerCase()]: {
        value: filter.value,
        condition: filter.type,
      },
      view: currentView,
      url: window.location.href,
    });
  };

  const isPopoverFilterInFilters = filters.some(
    (f: FilterColumn) =>
      f.columnLabel === popoverFilter?.columnLabel && f.type === popoverFilter?.type,
  );

  const selectedTags = validFilters.map((filter: FilterColumn) => {
    const column = findColumn(filter);
    if (!column) return null;

    const tagContent = (
      <span>
        <b>{filter.columnLabel}</b>
        {` ${findTypeName(filter.type)} `}
        <b>{`${filter.value}  ${column.wrapper}`}</b>
      </span>
    );
    return (
      <Tag
        isSelected
        key={`${filter.columnLabel}-${filter.value}`}
        onDelete={() => removeFilter(filter as PopoverFilterType)}
        onClick={event => {
          event?.stopPropagation();
          setAnchor(event?.currentTarget as HTMLElement);
          setPopoverFilter({
            ...filter,
            columnType: column.type,
            wrapper: column.wrapper,
          } as PopoverFilterType);
          trackerService.track(`Clicked filter tag: ${filter.columnLabel} `, {
            view: currentView,
            url: window.location.href,
          });
        }}
        data-qa={`active-filters-${filter?.columnLabel}`}
        className={cn({
          [styles.selectedFocus]:
            filter.columnLabel === popoverFilter?.columnLabel &&
            filter.type === popoverFilter?.type,
        })}
      >
        {tagContent}
      </Tag>
    );
  });

  const tags = [
    ...selectedTags,
    ...getPreselectedTags({
      defaultFilters,
      columns,
      filters,
      setAnchor,
      setPopoverFilter,
      popoverFilter,
      currentView,
    }),
  ];

  if (tags.length === 0) return null;

  return (
    <article className={styles.tags}>
      <HorizontalScroll className={cn({ [styles.rightScrollButton]: selectedTags.length > 0 })}>
        {showActiveFiltersLabel && (
          <div className={styles.activeFiltersHeader}>{translate('analytics_filters_active')}</div>
        )}
        {tags}
        {anchor && (
          <FilterPopover
            anchor={anchor}
            setAnchor={setAnchor}
            key={popoverFilter?.columnName}
            selectedFilter={popoverFilter as PopoverFilterType}
            clearFilter={removeFilter}
            applyFilter={handleApplyFilter}
            setSelectedFilter={setPopoverFilter}
            isFilterInFilters={isPopoverFilterInFilters}
          />
        )}
      </HorizontalScroll>
      {selectedTags.length > 0 && (
        <div>
          <button
            onClick={clearFilters}
            className={cn(styles.clearFiltersBtn, 'e-button e-button--small e-button--link')}
            data-qa="active-filters-clear"
          >
            {translate('analytics_filters_clear')}
          </button>
        </div>
      )}
    </article>
  );
};

type PreSelectedTagsProps = {
  defaultFilters: string[];
  columns: ColumnDef[];
  filters: FilterColumn[];
  setAnchor: (anchor: HTMLElement | null) => void;
  setPopoverFilter: (filter: PopoverFilterType) => void;
  popoverFilter: PopoverFilterType | undefined;
  currentView: string;
};

const getPreselectedTags = ({
  defaultFilters,
  columns,
  filters,
  setAnchor,
  setPopoverFilter,
  popoverFilter,
  currentView,
}: PreSelectedTagsProps) => {
  const getColumnDetails = (filterName: string) =>
    columns.find(
      (columnTmp: ColumnDef) => columnTmp.name.toLowerCase() === filterName.toLowerCase(),
    );

  const unselectedFilters: string[] = defaultFilters.filter(
    filter => !filters.some((f: FilterColumn) => f.columnLabel === filter),
  );

  const preselectedTags = unselectedFilters.map(item => {
    const column = getColumnDetails(item);
    if (!column) return null;

    const filter = {
      columnLabel: column.name,
      columnName: column.key,
      type: column.type === 'string' ? 'CONTAINS' : 'EQUALS',
      value: '',
    };

    return (
      <Tag
        key={column.key}
        qaId={`unselected-filters-${column.name}`}
        onClick={event => {
          event?.stopPropagation();
          setAnchor(event?.currentTarget as HTMLElement);
          setPopoverFilter({
            ...filter,
            columnType: column.type,
            wrapper: column.wrapper,
          } as PopoverFilterType);

          trackerService.track(`Clicked filter tag: ${filter.columnLabel}`, {
            view: currentView,
            url: window.location.href,
          });
        }}
        className={cn({
          [styles.focus]:
            filter.columnLabel.toLowerCase() === popoverFilter?.columnLabel.toLowerCase(),
        })}
      >
        <b>{column.name}</b>
      </Tag>
    );
  });

  return preselectedTags;
};
