import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  DropdownDiscriminatorType,
  DropdownOption,
  DropdownWithDynamicTitle,
  DropdownWithStaticTitle,
  Props,
} from './multi-select-dropdown.types';
import styles from './multi-select-dropdown.module.css';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { TooltipPosition, TooltipWrapper } from '../tooltip-wrapper';
import { translate } from '../../helpers/utils';
import ArrowIcon from 'tcp-react-icons/lib/ArrowIcon';
import cn from 'classnames';
import { ExclamationCircleFillDarkIcon, SearchLineDarkIcon } from 'tcp-react-icons';
import { OnboardingTooltip } from '../onboarding-tooltip';
import { Menu, MenuDirection } from '../menu';
import { Checkbox } from '../checkbox';
import TrackerService from '../../services/tracker/tracker-service';
import { MULTI_SELECT_DROPDOWN_APPLY, MULTI_SELECT_DROPDOWN_OPEN } from '../../constants';
import { InputIcon } from '../input-icon';
import { useCurrentView } from '../../hooks/use-current-view/use-current-view';

export const MultiSelectDropdown: FC<Props> = ({
  options,
  selectedByDefaultOptions,
  showSearchBox = false,
  isDisabled = false,
  onBoardingTooltipConfig,
  texts,
  menuDirection,
  onApply,
  tracking,
  className,
}) => {
  const [selectedOptions, setSelectedOptions] =
    useState<DropdownOption[]>(selectedByDefaultOptions);
  const [searchText, setSearchText] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const currentView = String(useCurrentView()).toLowerCase();

  useOnClickOutside(
    dropdownRef,
    useCallback(() => setIsOpen(false), []),
  );

  const onSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    if (showSearchBox) {
      inputRef.current?.focus();
    }
  }, [inputRef, selectedOptions, showSearchBox]);

  useEffect(() => {
    if (options.length > 0 && selectedByDefaultOptions.length === 0) {
      setSelectedOptions([options[0]]);
    }
  }, [options, selectedByDefaultOptions.length]);

  useEffect(() => {
    onIsOpenChanged();
  }, [isOpen]);

  const onIsOpenChanged = () => {
    if (!isOpen) {
      setSelectedOptions(
        selectedOptions.length === 0 ? [options[0]] : selectedByDefaultOptions.slice(),
      );
      setSearchText('');
    } else {
      if (showSearchBox) {
        inputRef.current?.focus();
      }
      if (tracking?.prefix)
        TrackerService.trackWithPrefix(tracking.prefix, MULTI_SELECT_DROPDOWN_OPEN);
      if (tracking?.base)
        TrackerService.track(tracking.base, { view: currentView, url: window.location.href });
    }
  };

  useEffect(() => {
    setSelectedOptions(selectedByDefaultOptions);
  }, [selectedByDefaultOptions]);

  const clickApply = () => {
    onApply(selectedOptions);
    setIsOpen(false);
    if (tracking?.prefix)
      TrackerService.trackWithPrefix(tracking.prefix, MULTI_SELECT_DROPDOWN_APPLY, {
        selectedOptions: selectedOptions.map(option => option.value),
      });
  };

  const optionsContain = (currentOptions: DropdownOption[], searchedOption: DropdownOption) => {
    return currentOptions.map(option => option.value).includes(searchedOption.value);
  };

  const selectOption = (option: DropdownOption) => {
    let clonedOptionsSelected: DropdownOption[];

    if (optionsContain(selectedOptions, option)) {
      clonedOptionsSelected = selectedOptions.filter(
        existingOption => existingOption.value !== option.value,
      );
    } else {
      clonedOptionsSelected = selectedOptions.slice();
      clonedOptionsSelected.push(option);
    }

    setSelectedOptions(clonedOptionsSelected);
  };

  const clearSelection = () => setSelectedOptions([]);

  const selectAll = () => setSelectedOptions(options.slice());

  const isSelectionInvalid = selectedOptions.length === 0;

  const assertExhaustive = (_: never): never => {
    throw new Error('Reached unexpected case in exhaustive switch');
  };

  const dropdownLabelText = (): string => {
    switch (texts.discriminatorColumn) {
      case DropdownDiscriminatorType.DYNAMIC:
        return dynamicTitle(texts as DropdownWithDynamicTitle, selectedOptions);
      case DropdownDiscriminatorType.STATIC:
        return (texts as DropdownWithStaticTitle).title;
      default:
        return assertExhaustive(texts);
    }
  };

  return (
    <div ref={dropdownRef}>
      <span className="u-display--inline-flex">
        <TooltipWrapper
          hideTooltip={!isDisabled || !texts.disabledTooltip}
          position={TooltipPosition.TOP_LEADING}
          noTinyPaddingTop={true}
          message={texts.disabledTooltip}
        >
          <button
            className={cn(
              'e-button e-button--tertiary e-button--small u-display--flex u-align-items--center',
              className,
            )}
            disabled={isDisabled}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            data-qa="multi-select-dropdown-button"
          >
            <span className="u-margin-right--small">{dropdownLabelText()}</span>
            <ArrowIcon
              className={cn('u-transition--quick', {
                'u-transform--rotate-90deg': !isOpen,
                'u-transform--rotate-minus-90deg': isOpen,
                [styles.multiSelectDropdownIconDisabled]: isDisabled,
              })}
            />
          </button>
        </TooltipWrapper>
        {texts.informationTooltip && (
          <TooltipWrapper
            position={TooltipPosition.TOP_CENTER}
            noTinyPaddingTop={true}
            message={texts.informationTooltip}
            classNameParent={styles.multiSelectTooltip}
          >
            <ExclamationCircleFillDarkIcon
              className={cn(
                'u-color--juri-very-light u-margin-left--small u-margin-top--medium u-margin-right--large',
              )}
              width={14}
              height={14}
            />
          </TooltipWrapper>
        )}
      </span>
      {onBoardingTooltipConfig !== undefined && (
        <div className={cn(styles.multiSelectDropdownOnBoarding)}>
          <OnboardingTooltip keyStorage={onBoardingTooltipConfig.storageKey}>
            <p>{onBoardingTooltipConfig.text}</p>
          </OnboardingTooltip>
        </div>
      )}
      <div className={cn(styles.multiSelectDropdownPopover, styles.hidePopoverArrow)}>
        {isOpen && (
          <Menu
            header={{
              html: (
                <>
                  {showSearchBox && (
                    <div className="u-padding-bottom--gutter--medium">
                      <InputIcon
                        className="c-input c-input--big u-width--100 u-border-radius"
                        placeholder={translate('shown_metrics_selector_search_placeholder')}
                        icon={SearchLineDarkIcon}
                        onChange={onSearchHandler}
                        value={searchText}
                        ref={inputRef}
                      />
                    </div>
                  )}
                  <div className="u-display--flex u-padding-bottom--gutter--small">
                    <div
                      className="u-cursor--pointer u-color--blue"
                      onClick={selectAll}
                      data-qa="multi-select-dropdown-select-all"
                    >
                      {translate('multi-select_dropdown_select_all')}
                    </div>
                    <div
                      className="u-margin-left--auto u-cursor--pointer u-color--blue"
                      onClick={clearSelection}
                      data-qa="multi-select-dropdown-clear"
                    >
                      {translate('multi-select_dropdown_clear_selection')}
                    </div>
                  </div>
                </>
              ),
              extraClasses: 'u-padding-top--large u-padding-right--large u-padding-left--large',
            }}
            body={{
              items: options.filter(filterByName(searchText)).map(option => (
                <div
                  key={options.indexOf(option)}
                  className="u-display--flex u-width--100 u-padding-w--tiny"
                >
                  <Checkbox
                    title={option.key}
                    isChecked={optionsContain(selectedOptions, option)}
                  />
                </div>
              )),
              extraClasses: `${styles.multiSelectDropdownMenuItems} u-padding-right--medium u-padding-left--medium`,
            }}
            footer={{
              html: (
                <>
                  <button
                    className={cn(
                      'e-button e-button--primary e-button--width-100  u-margin-top--large',
                      {
                        'u-margin-bottom--large': !isSelectionInvalid,
                      },
                      styles.multiSelectDropdownMenuFooterButton,
                    )}
                    onClick={event => {
                      event.stopPropagation();
                      clickApply();
                    }}
                    disabled={isSelectionInvalid}
                    data-qa="multi-select-dropdown-apply"
                  >
                    {translate('common_apply')}
                  </button>
                  {isSelectionInvalid && (
                    <span className="u-color--red u-text-align--center u-justify-content--center u-margin-auto u-display--flex ">
                      {texts.noneSelectedError}
                    </span>
                  )}
                </>
              ),
              extraClasses: `${styles.multiSelectDropdownMenuFooter}`,
            }}
            direction={menuDirection || MenuDirection.BottomTrailing}
            onSelectItem={(index: number) => {
              selectOption(options.filter(filterByName(searchText))[index]);
            }}
            qaId="multi-select-dropdown"
          />
        )}
      </div>
    </div>
  );
};

const filterByName = (searchText: string) => (column: DropdownOption) =>
  column.key.toLocaleLowerCase().includes(searchText.toLocaleLowerCase().toLowerCase());

const dynamicTitle = (texts: DropdownWithDynamicTitle, selectedOptions: DropdownOption[]) => {
  if (selectedOptions.length !== 1)
    return texts.multipleSelected.replace('$$', String(selectedOptions.length));
  const selectedOption = selectedOptions[0].key;
  if (!texts.singleSelected) return selectedOption;
  return texts.singleSelected.replace('$$', selectedOption);
};
