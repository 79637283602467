import { Props } from './checkbox.types';

export const Checkbox: React.FunctionComponent<Props> = ({
  isChecked,
  title,
  onChange,
  children,
  classNames,
  disabled,
}) => {
  const defaultClassNames = 'u-display--flex u-font-weight-normal u-align-items--center';

  return (
    <span className={classNames === undefined ? defaultClassNames : classNames} onClick={onChange}>
      <input type="checkbox" checked={isChecked} disabled={disabled} onChange={onChange} />
      <p className="u-margin-left--medium u-width--100">
        {title}
        {children}
      </p>
    </span>
  );
};
