import { View } from '../models';

// BIDDING
// short-cut (hotel view)
export const HOTEL_VIEW_UNSELECTED_ALL_PROPERTIES =
  'Hotel view: User clicks on deselect all properties';
export const HOTEL_VIEW_SELECTED_ALL_PROPERTIES =
  'Hotel view: User clicks on select all properties';
export const HOTEL_VIEW_OPEN_DETAILS = 'Hotel view: Open details';

export const UPLOADED_BIDS = 'User has uploaded a file';
export const REVERT = 'User clicks on revert all bids';
export const CONFIRM_REVERT = 'User clicks on confirm revert all bids';
export const UPLOADED_SL_BIDS = 'User has uploaded a SL file';
export const UPLOADED_INVENTORY = 'User has uploaded an inventory file';

// hotel control panel
export const MANUAL_APPLY_MULTIPLE_PROPERTIES =
  'Apply with Custom Edit option selected for multiple properties';
export const MANUAL_APPLY_SINGLE_PROPERTIES =
  'Apply with Custom Edit option selected for individual property selected';
export const MANUAL_APPLY_ALL_PROPERTIES =
  'Apply with Custom Edit option selected for All Properties';
export const MANUAL_APPLY_EXCEED_LIMIT_CPC =
  'Error is triggered because the value exceeds the limit of max CPC';
export const MANUAL_APPLY_SUCCESSFULLY_UPDATED = 'The bid (Custom Edit) is successfully updated';
export const CAMPAIGNS_BIDDING_CPA_VALUE_APPLIED =
  "Bidding: Cpa value applied from 'Campaigns' view";
export const SL_DETAILED_HOTEL_VIEW_PREFIX = 'SL Detailed Hotel View';
export const SL_HOTEL_VIEW_PREFIX = 'SL Hotel View';
export const DETAILED_HOTEL_VIEW_PREFIX = 'Detailed Hotel View';
export const HOTEL_VIEW_PREFIX = 'Hotel View';
export const HOTEL_VIEW_SHOW_NON_PERFORMING_DATA =
  'Toggle ‘Showing non-performing data’ was clicked';

// bid modifiers control panel
export const BIDDING_BID_MODIFIERS_SINGLE_BREAKOUT_APPLIED = (view: View) => {
  return `Bidding: Bid modifiers for single breakout applied from '${View[view]}' view`;
};

export const BIDDING_BID_MODIFIERS_MULTIPLE_BREAKOUTS_APPLIED = (view: View) => {
  return `Bidding: Bid modifiers for multiple breakouts applied from '${View[view]}' view`;
};

// BIDDING MODIFIERS
export const UPLOADED_BID_MODIFIERS = 'User has uploaded a bid modifier file';
export const UPLOADED_SL_BID_MODIFIERS = 'User has uploaded a SL bid modifier file';
export const UPLOADED_CPA_BID_MODIFIERS = 'User has uploaded a CPA bid modifier file';

// PROPERTY GROUPS
export const UPLOADED_PROPERTY_GROUP = 'User has uploaded a property group file';

// CPA MARKETPLACE
export const UPLOADED_CAMPAIGN_ALLOCATION = 'User has uploaded a campaign allocation file';
export const UPLOADED_CPA_VALUE = 'User has uploaded a cpa value file';

// BUDGET CAP
export const UPLOADED_SL_BUDGET_CAP = 'User has uploaded a SL budget cap file';

// REPORTS
export const REPORTS_ACCESS_REPORTS_DETAIL = 'Reports: Access reports detail';
export const REPORTS_DOWNLOAD_REPORT = 'Reports: Download report';

//Notifications
export const NOTIFICATIONS_READ_ALL = 'Notifications: Read all';
export const NOTIFICATIONS_READ = 'Notifications: Read notification';
export const NOTIFICATIONS_OPEN_DRAWER = 'Notifications: Open drawer';
export const NOTIFICATIONS_OPEN_SETTINGS = 'Notifications: Open Settings';
export const NOTIFICATIONS_UPDATE_SETTINGS = 'Notifications: Update Settings';

//SL analytics
export const SL_HOTEL_VIEW_OPEN_DETAILS = 'SL Hotel view: Open details';
export const SL_HOTEL_VIEW_SELECTED_ALL_PROPERTIES =
  'SL Hotel view: User clicks on select all properties';
export const SL_HOTEL_UNSELECTED_ALL_PROPERTIES =
  'SL Hotel view: User clicks on deselect all properties';

//Tabs
export const TRACK_NAME_ADMIN_TABS = 'Admin';
export const TRACK_NAME_ANALYTICS_TABS = 'Analytics';
export const TRACK_NAME_SL_ANALYTICS_TABS = 'SL Analytics';

export const TAB_CLICKED = (tabGroupName: string, title: string) => {
  return `Tab group '${tabGroupName}'. Tab '${title}' was clicked`;
};

//multi-select dropdown
export const MULTI_SELECT_DROPDOWN_APPLY = 'filtering is applied/changed';
export const MULTI_SELECT_DROPDOWN_OPEN = 'dropdown opened';
export const MULTI_SELECT_CAMPAIGN_PREFIX = 'Campaign: Campaign';
export const MULTI_SELECT_BM_POS_PG_PREFIX = (viewName: string) => {
  return `Bid modifiers ('${viewName}' view): Property Groups`;
};
export const SHOWN_METRICS_SELECTOR_PREFIX = (viewName: string) => {
  return `Shown columns selector ('${viewName}' view)`;
};
export const MULTI_SELECT_BIDDING_TYPE_CLICK = 'Clicked filter tag: Bidding Type';
//campaign view
export const CAMPAIGNS_VIEW_ACTIVE_ONLY_TOGGLE = (isActive: boolean) => {
  const checkedPrefix = isActive ? '' : 'un';
  return `Campaign: “Show only active” check box ${checkedPrefix}checked`;
};

export const FILTERS_MODAL_OPEN = 'Filters: Open modal';
export const FILTERS_ADD_FILTER = 'Filters: Add new filter';
export const FILTERS_SAVE = 'Filters: Save';
export const FILTERS_DELETE_FILTER = 'Filters: Delete filter';
export const FILTERS_RENAME = 'Filters: Rename filter set';
export const FILTERS_DELETE_FILTER_SET = 'Filters: Delete filter set';
export const FILTERS_APPLY_FILTER_SET = 'Filters: Apply filter set';
export const FILTERS_SAVE_AS = 'Filters: Save as';

// chart builder
export const CHART_BUILDER_CREATE_START = 'Chart builder: Create start';
export const CHART_BUILDER_CREATE_SUCCESS = 'Chart builder: Create success';
export const CHART_BUILDER_EDIT_START = 'Chart builder: Edit start';
export const CHART_BUILDER_EDIT_SUCCESS = 'Chart builder: Edit success';
export const CHART_BUILDER_DELETE = 'Chart builder: Delete';
export const CHART_BUILDER_CREATE_STAY = 'Chart builder: Create stay';

// date picker
export const DATE_PICKER_COMPARE_TOGGLE = 'Date picker: Compare toggle';
export const DATE_PICKER_COMPARE_CLICK = 'Date picker: Compare click';
export const DATE_PICKER_COMPARE_ADD_PERIOD = 'Date picker: Add compare period click';
