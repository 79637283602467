import { QUERY_PARAM_ACTIVE_TAB } from '../../constants';
import { GridContextMenu, View } from '../../models';
import { useQueryParam } from '../use-query-param';
import { useMemo } from 'react';

const menuItems: GridContextMenu[] = [
  { menu: 'Hotel' },
  { menu: 'Point of Sale' },
  { menu: 'Time Period' },
  { menu: 'Bidding Type' },
  {
    menu: 'Bid Modifiers',
    nestedMenu: [
      'Property Groups',
      'Length of Stay',
      'Time to Travel',
      'Default Date',
      'Group Size',
      'Combined Modifiers',
    ],
  },
  {
    menu: 'Other',
    nestedMenu: [
      'City',
      'Rating',
      'Country',
      'Accommodation Type',
      'Star Classification',
      'Region',
    ],
  },
];

export const useGridContextMenu = () => {
  const [activeTab] = useQueryParam(QUERY_PARAM_ACTIVE_TAB);

  const { column, menuItems: currentTabMenuItems } = useMemo(() => {
    const currentTab: string = View[activeTab as unknown as View] ?? 'hotel';

    const activeTabMenuItems = menuItems.filter(
      item => !item.menu.toLowerCase().includes(currentTab?.toLowerCase()),
    );

    const contextMenuColumns: Record<string, string> = {
      hotel: 'pid',
    };

    return {
      column: contextMenuColumns[currentTab?.toLowerCase()],
      menuItems: activeTabMenuItems,
    };
  }, [activeTab]);

  return { column, menuItems: currentTabMenuItems };
};
