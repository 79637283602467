import { FC, useState } from 'react';
import { ButtonIcon } from '../../../core-ui/components/button-icon/button-icon';
import { AnchoredMenu } from '../../../core-ui/components/menu/anchored-menu';
import { AnchorObject, MenuItem } from '../../../core-ui/components/menu/menu';
import { upperFirst } from 'lodash';
import sharedStyles from '../shared.module.css';
import styles from './grid-cell.module.css';
import { Tooltip } from '../../../core-ui/components/tooltip/tooltip';
import cn from 'classnames';
import { GridContextMenu } from '../../../models';

type GridCellContextMenuProps = {
  gridCell: JSX.Element | null;
  menuItems: GridContextMenu[];
  rowId: string | number;
  tooltipTitle?: string;
  menuTitle?: string;
};

export const GridCellContextMenu: FC<GridCellContextMenuProps> = ({
  gridCell,
  menuItems,
  rowId,
  tooltipTitle,
  menuTitle,
}) => {
  const [anchor, setAnchor] = useState<AnchorObject>({
    anchor: null,
    id: null,
  });

  if (!gridCell) return null;

  const anchorMenuItems: MenuItem[] = menuItems.map((item, index) => ({
    id: index,
    label: upperFirst(item.menu),
    onClick: () => console.log(item),
    nestedMenu: item.nestedMenu
      ? item.nestedMenu.map((nestedItem, nestedIndex) => ({
          id: nestedIndex,
          label: upperFirst(nestedItem),
          onClick: () => console.log(nestedItem),
        }))
      : undefined,
  }));

  return (
    <>
      <span className={styles.contextMenuGridCell}>
        {gridCell}
        <span
          onClick={e => setAnchor({ anchor: e.currentTarget as HTMLElement, id: rowId })}
          className={cn(sharedStyles.showOnHover, {
            [sharedStyles.activeShowOnHover]: anchor.id === rowId,
          })}
        >
          <Tooltip position="rightCenter" title={tooltipTitle ?? ''} isDelayed>
            <ButtonIcon
              icon={{ name: 'kebabMenu', width: 24, height: 18 }}
              onClick={e => setAnchor({ anchor: e.currentTarget as HTMLElement, id: rowId })}
              title=""
            />
          </Tooltip>
        </span>
      </span>
      {anchor && anchor.id === rowId && (
        <AnchoredMenu
          anchor={anchor.anchor as HTMLElement}
          onClose={() => setAnchor({ anchor: null, id: null })}
          items={anchorMenuItems}
          title={menuTitle ?? ''}
          style={{ width: 200 }}
        />
      )}
    </>
  );
};
