import { CSSProperties, FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import styles from './anchor.module.css';
import clamp from 'lodash/clamp';
import { createPortal } from 'react-dom';

export type AnchorProps = {
  anchor: HTMLElement | null;
  anchorOrigin?: { horizontal: 'left' | 'right' };
  onClose: VoidFunction;
};

export const Anchor: FC<AnchorProps> = ({
  anchor,
  anchorOrigin = { horizontal: 'left' },
  onClose,
  children,
}) => {
  const backdropRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [anchorStyle, setAnchorStyle] = useState<CSSProperties>({
    position: 'absolute',
  });

  const handleBackdropClick = useCallback(
    event => {
      if (event.target === backdropRef.current) onClose();
    },
    [onClose],
  );

  const calculateAnchorPosition = useCallback(() => {
    if (!anchor || !anchorRef.current) return null;

    const anchorRect = anchor.getBoundingClientRect();
    const anchorElementRect = anchorRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;

    const left = Math.round(clamp(anchorRect.left, 0, viewportWidth - anchorElementRect.width));
    const right = Math.round(
      clamp(viewportWidth - anchorRect.right, 0, viewportWidth - anchorElementRect.width),
    );

    const anchorCSSStyle: CSSProperties = {
      position: 'absolute',
      top: anchorRect.top + anchorRect.height,
      left: anchorOrigin.horizontal === 'left' ? left : undefined,
      right: anchorOrigin.horizontal === 'right' ? right : undefined,
    };

    return anchorCSSStyle;
  }, [anchor, anchorOrigin.horizontal]);

  const updateMenuPosition = useCallback(() => {
    const menuPosition = calculateAnchorPosition();
    if (menuPosition) {
      setAnchorStyle(menuPosition);
    }
  }, [calculateAnchorPosition]);

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';
    updateMenuPosition();

    window.addEventListener('scroll', updateMenuPosition);
    window.addEventListener('resize', updateMenuPosition);

    return () => {
      document.body.style.overflow = 'auto';

      window.removeEventListener('scroll', updateMenuPosition);
      window.removeEventListener('resize', updateMenuPosition);
    };
  }, [updateMenuPosition]);

  return createPortal(
    <div ref={backdropRef} onClick={handleBackdropClick} className={styles.backdrop}>
      <div ref={anchorRef} style={anchorStyle}>
        {children}
      </div>
    </div>,
    document.body,
  );
};
